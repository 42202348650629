.home-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url("https://i.imgur.com/RESkM0j.png");
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}

.acasa-text {
  margin-bottom: 40px;
}

.aboutDiv {
  width: 268px;
  padding: 26px;
  background: #726da8;
  border-radius: 15px;
  color: #f7f7ff;
  font-size: 20px;
  margin-top: 40px;
  margin-bottom: 20px;
}

.aboutDiv > p {
  margin: 0;
}

#description-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 10px;
}

#description-content {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
}

.countdown-mobile-text {
  color: #615d8f;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
}

.countdown-wrapper .countdown-timer {
  padding: 20px;
  font-size: 29px;
}

.countDownDiv {
  width: 292px;
  height: 80px;
  margin-top: 24px;
  border-radius: 15px;
}

.button-wrapper {
  margin-top: 20px;
  display: flex;
  column-gap: 20px;
}

.button-wrapper button {
  margin-top: 20px;
  margin-bottom: 20px;
}

.partenersText {
  width: 136px;
  height: 58px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 120%;
  text-align: left;

  color: #726da8;
}

.titleUnderlineParteners {
  /* Rectangle 39 */
  width: 180px;
  height: 4px;
  background: #1a936f;
  border-radius: 12px 0px 0px 12px;
}

.partenersTextFlex {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

.home-wrapper .partners-wrapper {
  margin-bottom: 50px;
}

.home-flex .aplica-box {
  margin-bottom: 100px;
}

.mobileLogo2 {
  position: absolute;
  height: 92px;
  width: 200px;
  margin-left: -24px;
}

.program-header > h2 {
  color: #726da8;
  text-align: right;
  margin-right: 15vw;
  margin-bottom: 0;
  font-size: 28px;
}

.program-header > hr {
  height: 4px;
  background: #1a936f;
  border: none;
  width: 40vw;
  min-width: 200px;
  margin-right: 0;
  border-radius: 12px 0px 0px 12px;
}

.program-header > hr:nth-of-type(2) {
  margin: auto;
  /* width: 100px; */
  min-width: 130px;
  max-width: 200px;
  height: 4px;
  border: none;
  box-shadow: none;
  border-radius: 12px 12px 12px 12px;
}

.program-header > p {
  /* width: 100%; */
  text-align: center;
  color: #726DA8;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;
  margin-bottom: 10px;
}

.program-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8vw;
}

.program-mobile p {
  font-size: 20px;
}

.program-mobile .program-time {
  font-weight: 600;
}

.program-arrow img:hover {
  cursor: pointer;
}

@media screen and (max-width: 675px) {
  .program-mobile {
    column-gap: 4vw;
  }

  .program-mobile p {
    font-size: 18px;
  }

  .program-mobile img {
    padding: 14px;
  }
}

@media screen and (max-width: 540px) {
  .program-mobile {
    column-gap: 4vw;
  }

  .program-mobile p {
    font-size: 16px;
  }
}

@media screen and (max-width: 477px) {
  
}

@media screen and (min-width: 340px) {
  .aboutDiv {
    width: 292px;
  }

  .countdown-wrapper .countdown-timer {
    padding: 20px;
    font-size: 30px;
  }
}
