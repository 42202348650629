@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");
.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 200px;
  width: 100vw;
  overflow: hidden;
  /* todo remove */
  background-image: URL("../assets/images/faq.jpg");
}

.makeMargin {
  padding: 50px;
}

.title {
  font-size: 36px;
  color: #726da8;
  position: relative;
  font-weight: bold;
  margin-bottom: 64px;
  margin-top: 50px;
}

.title::after {
  content: "";
  position: absolute;
  bottom: -12px;
  left: -40px;
  width: 100vw;
  height: 4px;
  background-color: #1a936f;
  border-radius: 12px 0px 0px 12px;
}

.partnerImage {
  width: 200px !important;
  height: 100px;
  object-fit: contain;
  margin-top:10px;
}

.challengeCard {
  display: flex;
  margin-bottom: 80px;
  height: 100px;
  width: 600px;
  /* width: 800px; */
  /* align-self: flex-start; */
}

.infoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 36px;
  width: 100%;
}

.challengeCard:nth-of-type(5) > .partnerImage {
  margin-right: 58px;
}

.clickyButton {
  color: white;
  font-size: 18px;
  font-weight: bold;
  background-color: #726da8;
  border-radius: 8px;
  padding: 4px 14px;
  margin-top: 16px;
  cursor: pointer;
}

@media screen and (max-width: 420px) {
  .title {
    margin-top: 40px;
  }
  .challengeCard {
    /* display: flex; */
    flex-direction: column;
    width: 100vw;
    height: auto;
    align-items: center;
    margin-bottom: 40px;
  }

  .infoWrapper {
    margin-left: 0px;
  }
}

@media screen and (max-width: 560px) {
  .mobileLogo {
    height: 67.6px;
    width: 148.5px;
  }

  .challengeWrapper {
    flex-direction: column;
  }
}

/*mine*/
.challengeWrapper{
  display:flex;
  padding:15px 15px 15px 15px;
  margin:20px 0px 20px 0px;
  border-radius: 20px;
  gap:30px;
  background-color: white;
  transition: transform 0.3s ease-in-out;
}
.challengeWrapper:hover {
  transform: translate(10px, -10px);
}
.challengeTime, .challengeLocation, .challengeTitle{
  display:flex;
  align-items: center;
  color:#827EB3;
  font-size: 18px;
  font-weight: 500;
}
.challengeTitle{
  font-weight:700;
  font-size:20px;
}

.challengeTime > img, .challengeLocation > img {
  height:18px;
  color:red;
  padding-right: 5px;
}
.orange{
  background-color: #EF7B45;
}

