@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap%27");

.bg {
  position: fixed;
  opacity: 80%;
  height: 100%;
  width: 100%;
  object-fit: cover;
  margin-top: 10%;
  z-index: -1;
}

.FAQText {
  width: 110px;
  height: 60px;
  margin-right: 40px;
  text-align: top;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 120%;
  /* or 29px */

  text-align: center;
  /* identical to box height, or 29px */

  /* mov */

  color: #726da8;
}

.FAQTextFlex {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

.titleUnderlineFAQ {
  /* Rectangle 39 */
  width: 145px;
  height: 4px;
  background: #1a936f;
  border-radius: 12px 0px 0px 12px;
  align-self: flex-end;
}

.bottomUnderlineFAQ {
  width: 145px;
  height: 8px;
  background: #1a936f;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px 0px 0px 12px;
  align-self: flex-end;
  display: none;
}

.apply {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 48px;
  margin-bottom: 30px;
}

.faqFlex {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}

html {
  height: 100%;
}

.body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

footer {
  display: flex;
  flex-direction: column;
  margin-top: auto;
}

.mobileLogo3 {
  position: absolute;
  height: 92px;
  width: 200px;
  margin-left: -24px;
}

@media screen and (min-width: 800px) {
  .mobileLogo3 {
    right: 0px;
  }

  .p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  .apply {
    margin-top: 10px;
  }

  .bg {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 80%;
    height: 100%;
    width: 100%;
    object-fit: contain;

    z-index: -1;
    margin-top: 5%;
    margin-bottom: 30%;
  }

  .FAQText {
    display: flex;
    flex-direction: column;
    font-size: 40px;
    text-align: center;
    font-family: "Montserrat";
    font-style: normal;
    align-self: flex-end;
    margin-right: 46.5%;
    margin-bottom: 50px;
  }

  .titleUnderlineFAQ {
    display: flex;
    flex-direction: column;
    height: 4px;
    background: #1a936f;
    border-radius: 0px 12px 12px 0px;
    position: absolute;
    right: 45%;
    width: 55%;
    left: 0;
    align-self: flex-end;
    margin-top: 87px;
  }

  .FAQTextFlex {
    margin-bottom: 100px;
    display: flex;
    text-align: top;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .bottomUnderlineFAQ {
    width: 55%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 80px;
    height: 4px;
    background: #1a936f;
    border-radius: 12px 12px 12px 12px;
    margin-left: 17%;
  }
}

.body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

footer {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;
}


@media screen and (min-width: 958px) {
  .mobileLogo3 {
    display: none;
  }
}
