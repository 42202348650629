.devs-flex {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: fit-content;
}

.titleUnderline-flex {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.text-arhitects {
  width: fit-content;
  height: 72px;
  margin-bottom: 8px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 120%;
  margin-right: 30%;
  align-self: flex-end;
  /* or 43px */

  /* mov */

  color: #726da8;
}

.arhitectsUnderline {
  width: 80%;
  height: 4px;
  background: #1a936f;
  border-radius: 0px 12px 12px 0px;
  margin-bottom: 24px;
}

.templarsUnderline {
  width: 80%;
  height: 4px;
  background: #1a936f;
  border-radius: 12px 0px 0px 12px;
  margin-bottom: 24px;
  align-self: flex-end;
}

.projectManager {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: fit-content;
}

.teamFlex {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.text-templars {
  width: fit-content;
  height: 72px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 120%;
  margin-left: 30%;
  margin-bottom: 8px;
  /* or 43px */

  /* mov */

  color: #726da8;
}

.family {
  margin-bottom: 140px;
}

@media screen and (max-width: 610px) {
  .devs-flex {
    display: flex;
    flex-direction: column;
  }

  .family {
    margin-bottom: 0px;
  }
}
