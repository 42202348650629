.program-time {
    color:white;
    padding:6px 15px 6px 15px;
    background-color: #615D8F;
    border-radius: 10px 10px 10px 0px;
    position: relative;
    z-index: 0;
}

.program-name {
    color:white;
    position: relative;
    z-index: 1;
    background-color: #726DA8;
    font-weight: 400;
    border-radius: 0px 10px 10px 10px;
    padding:6px 15px 6px 15px;
    margin-bottom: 25px;
    display:flex;
    justify-content: space-between;
}

.program-name-name {
    text-align: left;
}

.program-name-day {
    text-align:right;
}

.programDayContainer {
    position:relative;
    top:20px;
}