.partner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  column-gap: 6vw;
  row-gap: 20px;
}

.partner > div > span > a {
  margin-right: 20px;
  margin-left: 20px;
}

.partner h2 {
  text-align: right;
  font-size: 28px;
  margin-bottom: 20px;
}

.partner-img {
  opacity: 0.8;
  transition: opacity 0.2s ease-in-out;
}

.partner-img:hover {
  opacity: 1;
}
