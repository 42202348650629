.aplica-boxMenu {
  width: 164px;
  height: 41px;

  /* portocaliu */

  background: #ef7b45;
  border-radius: 15px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  display: flex;
  justify-content: center;
  align-items: center;
}

.aplica-box:active {
  background-color: #ee6323;
}

.aplica-text {
  text-align: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;

  line-height: 20px;

  color: #ffffff;
}

.noDecoration {
  text-decoration: none;
}
