@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

.nav-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  margin-bottom:50px;
}

/* Navbar */
.navbar {
  height: 105px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 30px;
}

.nav-logo-link {
  margin-right: auto;
  margin-left: 40px;
}

.nav-logo {
  height: 90px;
}

.nav-text {
  display: flex;
  /* justify-content: flex-start; */
  align-items: center;
  list-style: none;
  height: 40px;
}

.nav-text a {
  text-decoration: none;
  /* font-family: "Montserrat"; */
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  /* border-radius: 15px; */
  transition: 0.1s all linear;
  border: 0px solid transparent;
}

.nav-text a:hover {
  /* border: 2px solid #726da8; */
  border-bottom: 4px solid #1A936F;
}

.nav-text a {
  padding-left: 20px;
  padding-right: 20px;
  color: #726da8;
}

.nav-button {
  display: flex;
  text-decoration: none;
  align-items: center;
  list-style: none;
}

.nav-button .aplica-box {
  background: #ef7b45;
  margin-right: 40px;
}
/* Sidebar */

.sidebar {
  z-index: 20;
  background-color: #726da8;
  width: 80vw;
  max-width: 450px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
}

.sidebar.active {
  left: 0;
  transition: 450ms;
}

.sidebar-items {
  width: 100%;
}

.sidebar-logo-item {
  margin-top: 90px;
  /* width: 90%; */
  max-width: 300px;
}

.sidebar-logo {
  height: 80%;
  margin-left: 20px;
}

.sidebar-items {
  list-style: none;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}

.sidebar-items li {
  padding-left: 20px;
  width: 40%;
  height: 50px;
}

.sidebar-text a {
  text-decoration: none;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  border-radius: 15px;
  width: 100%;
  color: white;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
}

.sidebar-button {
  margin-top: 0px;
}

.navbar-toggle {
  background-color: #726da8;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.toggle-button {
  z-index: 5;
  position: fixed;
  background: #726da8;
  border: none;
  height: 50px;
  width: 50px;
  align-content: center;
  font-size: 22px;
  cursor: pointer;
  transition: all 160ms ease-in;
  top: 0px;
  left: 0px;
}

.toggle-button:before {
  content:"";
  height:50px;
  width:100vw;
  background-color:#726da8;
  position:absolute;
  left:0px;
  top:0px;
}

.toggle-button img {
  width: 65%;
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(13%)
    hue-rotate(237deg) brightness(104%) contrast(104%);
}

.toggle-button:active {
  transform: scale(0.95);
}

.overlay-active {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: rgba(124, 124, 124, 0.356);
  transition: all 0.2s linear;
}

@media screen and (max-width: 1040px) {
  .navbar {
    column-gap: 15px;
  }
}

@media screen and (max-width: 958px) {
  .navbar {
    display: none;
  }
  .menu-bars {
    display: block;
  }
}

@media screen and (min-width: 959px) {
  .toggle-button {
    display: none;
  }
  .sidebar {
    display: none;
  }
  .overlay-active {
    display: none;
  }
}

@media screen and (min-width: 440px) {
  .sidebar-text a {
    font-size: 28px;
  }

  .sidebar-items li {
    margin-left: 60px;
  }
}

@media screen and (min-width: 500px) {
  .sidebar-text a {
    font-size: 32px;
  }

  .sidebar-items li {
    margin-left: 80px;
  }
}
