
.FAQ{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 50px;
}
    



.question-container {
    display: flex;
    flex-direction: column;
    height: 68px;
    padding: 0;
    background: #615D8F;
    border: none;
    outline: none;
    border-radius: 10px;
    overflow: hidden;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 500; 
    cursor: pointer;
    transition: all 160ms ease-in;
    width: 300px;
    filter: drop-shadow(0px 6px 4px rgba(0, 0, 0, 0.4));
    margin-bottom: 20px;
    transition: all .3s ease;
}






.q-content {
  display: flex;
  width: 100%;
  height: auto;
}

.question-text {
    display: flex;
    align-items: center;
    justify-content: center;   
    padding: 0px 16px;
    color: white;
    order: 1;
    flex: 20 1;
    flex-direction: column;
    margin-top: 0;
    width: auto;
    text-align: left;
    font-size: 16px;

}

.question-icon {
  background-color: #5b5786;
  display: flex;
  align-items: center;
  height: 55px;
  padding: 0 15px;
  color: white;
  order: 2;
  height: 68px;
  overflow: hidden;
}

.question-icon > img {
  height: 22px;
  transform: rotateZ(180deg);
}

.expanded .question-icon > img {
  transform: none;
}

.hidden-text {
  display: none;
  margin-top: 10px;
  margin-bottom: 10px;
  color: white;
  font-size: 12px;
  padding: 0 15px;
  text-align: left;
  transition: all .3s ease;
}

.expanded {
  height: fit-content;
}

.expanded .hidden-text {
  display: flex;
  flex-direction: row;
  height:fit-content;
}

@media screen and (min-width:800px) {   
    
  .FAQ{
    width:100%;
  }

  .question-container {
    width: 50%;
    margin-bottom: 30px;
  }

  .q-content {
    width: 100%;
      
  }

  .hidden-text{
    justify-content: center;
  }

  .question-text{
    font-size: 20px;
    
  }

  .hidden-text{
    font-size:16px;
  }
}