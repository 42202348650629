.partners-div {
  overflow: hidden;
}

.partners-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: right;
  flex-wrap: wrap;
  width: 100%;
}

.partners-wrapper > div {
  width: 100%;
}

.first-row,
.second-row,
.third-row {
  width: 50%;
}

.third-row {
  margin-bottom: 10px;
}

.fourth-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 40px;
}

/* SUBTITLE */

.partners-div > h2 {
  color: #726da8;
  font-size: 36px;
  text-align: right;
  margin-right: 14vw;
  margin-bottom: 0;
}

.partners-wrapper h2 {
  color: #726da8;
  font-size: 30px;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 10px;
}

.fourth-row h2 {
  text-align: center;
}

/* GREEN LINE */

.partners-wrapper hr {
  height: 8px;
  width: 250px;
}

.partners-wrapper > div hr {
  margin-bottom: 50px;
}

.partners-wrapper > div:nth-child(1) hr {
  margin-bottom: 30px;
}

.partners-div hr {
  height: 1px;
  background: #726da8;
  
  border: none;
  border-radius: 12px;
  margin-bottom: 10px;
  margin-top: 10px;
  text-align: center;
}

.partners-div > hr {
  height: 4px;
  background: #1a936f;
  width: 30vw;
  min-width: 400px;
  margin-right: 0;
  border-radius: 12px 0px 0px 12px;
}

.fourth-row hr {
  text-align: center;
  width: 80%;
  min-width: 160px;
  margin: auto;
  border-radius: 12px 12px 12px 12px;
}

.starter-div,
.logistic-div {
  position: relative;
}

.starter-div:after,
.logistic-div:after {
  content: "";
  height: 150px;
  width: 1px;
  position: absolute;
  right: -20px;
  top: 20px;
  background-color: #726da8;
}

/* LOGO SIZES */

.first-row img {
  /* height: 64px; */
  max-height: 100px; 
  min-width:64px;
  object-fit: contain;
}

.second-row img {
  height: 64px;
}

.third-row img {
  height: 44px;
}

.fourth-row img {
  max-height: 64px;
  max-width: 130px;
  object-fit: contain;
}

.academic-div img {
  /* margin-top: -20px; */
  height: 60px;
}

.starter-div .partner span:nth-child(2) img {
  /* margin-top: -20px; */
  height: 54px;
}


@media screen and (max-width: 1356px) {
  .partners-wrapper h2 {
    margin-top: 20px;
  }
}

@media screen and (max-width: 1204px) {
  .first-row img {
    height: 90px;
  }
  .second-row img {
    height: 50px;
  }
  .third-row img {
    height: 40px;
  }
  .fourth-row img {
    height: 30px;
  }
  .academic-div img {
    height: 56px;
  }
}

@media screen and (max-width: 1102px) {
  .first-row img {
    height: 80px;
  }
  .second-row img {
    height: 44px;
  }
  .third-row img { 
    height: 54px;
  }
  .fourth-row img {
    height: 54px;
  }
  .academic-div img {
    height: 58px;
  }
}

@media screen and (max-width: 975px) {
  .third-row img {
    height: 54;
  }
}

/* LINII VERTICALE */
@media screen and (max-width: 1653px) {
  .logistic-div:after {
    display: none;
  }

  .academic-div hr {
    width: 250px;
  }
}


@media screen and (max-width: 1388px) {
  .starter-div:after {
    display: none;
  }
  .logistic-div:after {
    display: block;
  }
  .starter-div hr {
    width: 250px;
  }
}



@media screen and (max-width: 1331px) {
  .logistic-div:after {
    display: none;
  }
  .logistic-div hr {
    width: 250px;
  }
}

/* Mobil */

@media screen and (max-width: 958px) {
  .first-row img {
    height: 84px;
  }
  .second-row img {
    height: 80px;
  }
  .third-row img {
    height: 64px;
  }
  .fourth-row img {
    height: 64px;
  }
  .academic-div img {
    height: 58px;
  }

  .fourth-row {
    flex-direction: column;
  }

  .partners-div > hr {
    width: 40vw;
    min-width: 200px;
  }

  .partners-div > h2 {
    font-size: 28px;
  }

  .partners-wrapper h2 {
    font-size: 24px;
  }

  .partners-wrapper hr {
    width: 200px;
  }
}

@media screen and (max-width: 958px) {
  .starter-div:after,
  .logistic-div:after {
    display: block;
  }
}

@media screen and (max-width: 670px) {
  .first-row img {
    height: 84px;
  }
  .second-row img {
    height: 60px;
  }
  .third-row img {
    height: 54px;
  }
  .fourth-row img {
    height: 54px;
  }
  .academic-div img {
    height: 58px;
  }
}

@media screen and (max-width: 532px) {
  .first-row img {
    height: 90px;
  }
  .second-row img {
    height: 50px;
  }
  .third-row img {
    height: 54px;
  }
  .fourth-row img {
    height: 54px;
  }
  .academic-div img {
    height: 58px;
  }
}
