.location-button2 {
  display: flex;
  height: 55px;
  padding: 0;
  background: #615d8f;
  border: none;
  outline: none;
  border-radius: 10px;
  overflow: hidden;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  cursor: pointer;
  transition: all 160ms ease-in;
  width: 292px;
  filter: drop-shadow(0px 6px 4px rgba(0, 0, 0, 0.4));
}

.location-button2:active {
  transform: scale(0.95);
}

.location-button-icon2,
.location-button-text2 {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 30px;
  color: white;
}

.location-button-icon2 {
  background-color: #5b5786;
}

.location-button-icon2 > img {
  height: 22px;
}
