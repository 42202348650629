    #contact-form{
        width: 98%;
        height: 520px;
        margin-left: 1%;
        background-color: #726DA8;
        box-shadow: 0px 4px 4px 0px #00000040;
        border-radius: 12px;
        margin-bottom: 30px;
    }
    #message{
        width: 101px;
        height: 20px;
        margin-left: 37px;
        margin-top: 10px;

        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;

        color: #F7F7FF;
    }

    #name{
        width: 155px;
        height: 20px;
        margin-left: 37px;
        margin-top: 10px;

        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;

        color: #F7F7FF;
    }

    #email{
        width: 140px;
        height: 20px;
        margin-left: 37px;
        margin-top: 20px;

        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;

        color: #F7F7FF;
    }

    #name-input{
        width: 245px;
        height: 43px;
        margin-left: 37px;
        margin-top: 10px;

        background: #F7F7FF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 12px;
    }

    #email-input{
        width: 245px;
        height: 43px;
        margin-left: 37px;
        margin-top: 10px;

        background: #F7F7FF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 12px;
    }

    #message-input{
        width: 245px;
        height: 173px;
        margin-left: 37px;
        margin-top: 10px;

        background: #F7F7FF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 12px;
    }

    #name-input{
        width: calc(100% - 74px);
    }
    #email-input{
        width: calc(100% - 74px);
    }
    #message-input{
        width: calc(100% - 74px)
    }

    #trimite{
        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
        width: 164px;
        height: 41px;
        margin-left: 78px;
        margin-bottom: 25px;

        background: #EF7B45;
        border-radius: 15px;

        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;

        color: #FFFFFF;
    }

    .succes{
        color: rgb(12, 218, 12) !important;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
    }

    .fail{
        color: #EF7B45;
    }

    #trimite:active{
        background-color: #c55e2f;
    }

    #trimite{
        margin-left: calc(50% - 82px);
        display: block;
        margin-bottom: 7px;
    }

    #Mesaj{
        text-align: center;
        display: inline-block;
    }

    input{
        border-color:rgba(0, 0, 0, 0);
    }

    textarea{
        border-color:rgba(0, 0, 0, 0);
    }

    button{
        border-color:rgba(0, 0, 0, 0);
    }

    #fail-svg{
        margin-left: calc(50% - 115.758px);
        margin-right: 10px;
        vertical-align: middle;
    }

    #success-svg{
        margin-left: calc(50% - 61.833px);
        margin-right: 10px;
        vertical-align: middle;
    }

@media only screen and (min-width: 375px){
    #contact-form{
        width: 350px;
        margin-left: calc(50% - 175px);
    }
}

@media only screen and (min-width: 600px) and (max-width: 840px){
    #contact-form{
        width: 90% !important;
    }
}
#message {
  width: 101px;
  height: 20px;
  margin-left: 37px;
  margin-top: 10px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  color: #f7f7ff;
}

@media only screen and (min-width: 600px){
    #contact-form{
        width: 60%;
        height: 65%;
        margin-top: 50px;
        margin-bottom: 100px;
        margin: auto;
    }
    #Mesaj{
        margin-top: -10px;
    }
}