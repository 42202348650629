.home-desktop-wrapper {
  display: flex;
}

.home-left {
  position: fixed;
  background: url("https://i.imgur.com/I7P6UYW.png") no-repeat;
  background-size: cover;
  width: 454px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: center;
  justify-content: center;
  height: 100%;
  z-index: 1;
}

.home-right {
  width: calc(100% - 454px);
  margin-left: 454px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about {
  width: 80%;
  max-width: 700px;
  padding: 10px 23px 10px 23px;
  background: #726da8;
  border-radius: 15px;
  color: #f7f7ff;
  font-size: 20px;
  margin-top: 40px;
  margin-bottom: 30px;
}

#description-title-desktop {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 10px;
}

#description-content-desktop {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 21px;
  letter-spacing: 0.01em;
}

.home-right .countdown-timer {
  padding: 20px;
}

.countdown-desktop-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #1A936F;
}

.home-right .partners-div {
  margin-top: 20px;
  margin-bottom: 30px;
}
