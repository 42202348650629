.program-wrapper {
  width: 100%;
  /* font-family: "Montserrat"; */
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
  margin-top: 50px;
}

.program-wrapper > h2 {
  color: #726da8;
  font-size: 36px;
  font-weight: bold;
  text-align: right;
  margin-right: 14vw;
  margin-bottom: 24px;
}

.program-wrapper > hr {
  height: 4px;
  background: #1a936f;
  border: none;
  width: 30vw;
  min-width: 400px;
  margin-right: 0;
  border-radius: 12px 0px 0px 12px;
}
.joi h2, .vineri h2 {
  text-align: center;
}


.program-content {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  column-gap: 50px;
}

.program-content h3 {
   margin-bottom: 20px;
   color: #726da8;
  font-size: 24px;
}

.joi > h3 {
    font-weight: 700px;
    margin-left: 24px;
}

.vineri > h3 {
    font-weight: 700px;
    margin-left: 10px;
}

.program-content hr {
  height: 4px;
  background: #1a936f;
  border-radius: 12px 12px 12px 12px;
  border: none;
  margin-left: 0;
  width: 165px;
  margin-bottom: 40px;
}


@media screen and (max-width: 1545px) {
    .program-wrapper {
        font-size: 18px;
    }
}

@media screen and (max-width: 1350px) {
    .program-content {
        margin-left: 10px;
    }
}

@media screen and (max-width: 1270px) {
    .program-content {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

