footer {
  background: #726da8;
  padding-top: 10px;
  text-align: center;
  color: #ffffff;
  font-size: 16px;
  line-height: 0px;
  z-index:15;
  position:relative;
}

hr {
  width: 90%;
  margin-bottom: 20px;
}

.icon {
  height: 35px;
  margin-top: -10px;
  margin-left: 15px;
  margin-right: 15px;
  transition: opacity 0.2s ease-in-out;
}

.icon:hover {
  opacity: 0.8;
}

@media screen and (min-width: 600px) {
  footer {
    font-size: 20px;
  }
}

@media screen and (max-width: 326px) {
  footer {
    font-size: 14px;
  }
}
