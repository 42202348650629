.location-button {
  display: flex;
  height: 55px;
  padding: 0;
  background: #615d8f;
  border: none;
  outline: none;
  border-radius: 10px;
  overflow: hidden;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  cursor: pointer;
  transition: all 160ms ease-in;
  width: 292px;
  filter: drop-shadow(0px 6px 4px rgba(0, 0, 0, 0.4));
}

.location-button:active {
  transform: scale(0.95);
}

.location-button-icon,
.location-button-text {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 30px;
  color: white;
}

.location-button-icon {
  background-color: #5b5786;
}

.location-button-icon > img {
  height: 22px;
}

@media screen and (max-width: 958px) {
  .location-button {
    height: 126px;
    width: 138px;
    flex-direction: column;
    font-size: 16px;
    align-items: center;
  }

  .location-button-icon > img {
    height: 25px;
  }

  .location-button-text {
    margin-left: 0;
  }

  .location-button-icon,
  .location-button-text {
    display: flex;
    justify-content: center;
    vertical-align: middle;
    width: 100%;
    padding: 0;
  }
}
