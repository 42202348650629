.countdown-timer {
  font-size: 46px;
  font-style: Lato-Medium;
  font-style: bold;
  color: #EF7B45;
  text-align: center;
  display: flex;
  align-items: center;

  /* Se schimba in "transparent" cand se pune pe bg-ul paginii */

  width: fit-content;
  padding: 5px;
  border-radius: 10px;
}

.countdown-timer__time {
  font-weight: bold;
  display: inline-block;
  margin-left: 1px;
}

.countdown-timer__time-label {
  font-size: 46px;
  font-style: bold;

  color: #EF7B45;
  font-style: Roboto;
}

.line {
  height: 48px;
  width: 3px;

  background-color: #EF7B45;
  /* position: relative; */
  /* left: 5px;
    right: 10px;  */
  margin: 10px;
}

.container {
  margin-left: 1px;
}

.marebloc {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 30px;
}

/* .text {
    position: absolute;
    z-index: 1; */

@media screen and (max-width: 958px) {
  .countdown-timer {
    font-size: 48px;
    font-style: Lato-Medium;
    font-style: bold;
    color: #EF7B45;
    text-align: center;
    display: flex;
    align-items: center;

    /* Se schimba in "transparent" cand se pune pe bg-ul paginii */

    width: fit-content;
    padding: 5px;
    border-radius: 10px;
  }

  .countdown-timer__time {
    font-weight: bold;
    display: inline-block;
    margin-left: 1px;
  }

  .countdown-timer__time-label {
    font-size: 30px;
    font-style: bold;

    color: #EF7B45;
    font-style: Roboto;
  }

  .line {
    height: 36px;
    width: 1px;

    background-color: #EF7B45;
    /* position: relative; */
    /* left: 5px;
          right: 10px;  */
    margin: 10px;
  }
}
