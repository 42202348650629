.cardboxFlex {
  margin-top: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex:30%;
}

.cardImage {
  width: 186px;
  height:150px;
  height: fit-content;
  object-fit: contain;
  /* border-radius: 50%; */
}

.pName {
  width: auto;
  height: 24px;
}

.pNameText {
  width: auto;
  height: 24px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 120%;
  /* identical to box height, or 24px */

  letter-spacing: 0.09em;

  background: linear-gradient(225deg, #726da8 0%, #9891e1 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.pTitle {
  margin-top: 6px;
  width: 209px;
  height: fit-content;
}

.pTitleText {
  width: auto;
  height: 19px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  /* or 19px */
  text-align: center;
  letter-spacing: 0.02em;

  /* Rich Black */

  color: #071013;

  opacity: 0.5;
}

.pPhone {
  margin-top: -6px;
  width: auto;
  height: 14px;
}

.pPhoneText {
  width: auto;
  height: 14px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  /* or 14px */

  letter-spacing: 0.02em;

  /* Rich Black */

  color: #071013;

  opacity: 0.8;
}

.pMail {
  width: auto;
  height: 14px;
}

.pMailText {
  width: auto;
  height: 14px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  /* or 14px */

  letter-spacing: 0.02em;

  /* Rich Black */

  color: #071013;

  opacity: 0.8;
}

/* mine */
.imageDetails{
  background-color: #1a935f;
  color:white;
  width:200px;
  border-radius: 10px;
  text-align: center;
  padding:5px 40px;
  /* #1A936F */
}
#content .imageDetails{
  width:70%;
}
.nameText{
  font-family: Montserrat;
  font-size: 18px;
  letter-spacing: -1px;
}
.positionText{
  font-family: "Montserrat";
  font-size: 9px;
}