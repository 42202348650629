html {
  height: 100%;
}

body {
  background: #f7f7ff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.contactPersonsText {
  width: 176px;
  height: 84px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 120%;
  /* or 29px */

  text-align: center;
  /* identical to box height, or 29px */

  /* mov */

  color: #726da8;
}

.titleUnderlineContact {
  /* Rectangle 39 */
  width: 205px;
  height: 4px;
  background: #1a936f;
  border-radius: 12px 0px 0px 12px;
  margin-top: 5px;
}

.contactPersonsTextFlex {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

.menuButton {
  position: fixed;
  width: 46px;
  height: 46px;
  left: 12px;
  top: 36px;
  background: #726da8;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
}

.team-flexx {
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.cardBox {
  width: 217.44px;
  height: 206.86px;
}

.footer {
  width: 104%;
  height: 81px;
  margin-top: 48px;
  /* mov */

  background: #726da8;
}

.contactUs {
  width: fit-content;
  height: 58px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 120%;
  /* or 29px */
  text-align: left;
  /* mov */

  color: #726da8;
}

.titleUnderlineUs {
  /* Rectangle 39 */
  width: 275px;
  height: 4px;
  background: #1a936f;
  border-radius: 12px 0px 0px 12px;
}

.contactUsTextFlex {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

.form {
  width: 318px;
  height: 477px;
  margin-top: 24px;

  /* mov */

  background: #726da8;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
}

.mobileLogo1 {
  position: absolute;
  height: 92px;
  width: 200px;
  margin-left: -24px;
  margin-top: 12px;
}


@media only screen and (max-width: 340px) {
  .mobileLogo1 {
    height: 85.05px;
    width: 189px;
    margin-left: -32px;
    margin-top: 14px;
  }
}

@media only screen and (min-width: 1200px) {
  #container-whole-flex {
    background-image: url("https://i.imgur.com/v32G9x9.png");
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: auto;
    margin-bottom: -15px;
  }
}

@media only screen and (min-width: 600px) {
  #container-whole-flex {
    display: flex;
  }
  #container-persons {
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    margin-bottom: 100px;
  }
  #container-contact {
    width: 55%;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .contactPersonsTextFlex {
    align-items: center;
    margin-bottom: 10px;
  }
  .titleUnderlineContact {
    display: none;
  }
  .titleUnderlineUs {
    display: none;
  }
  .contactPersonsText {
    width: fit-content;
  }
  .contactUs {
    width: fit-content;
  }
}

@media screen and (min-width: 600px) {
  .mobileLogo1 {
    display: none;
  }
}