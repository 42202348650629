.FAQ {
  margin-top: 80px;
}

.question-container2 {
  display: flex;
  flex-direction: column;
  height: 68px;
  padding: 0;
  background: #615d8f;
  border: none;
  outline: none;
  border-radius: 10px;
  overflow: hidden;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  transition: all 160ms ease-in;
  width: 300px;
  filter: drop-shadow(0px 6px 4px rgba(0, 0, 0, 0.4));
  justify-content: center;
}

.q-content2 {
  display: flex;
  width: 100%;
  height: auto;
}

.question-text2 {
  display: flex;
  align-items: left;
  justify-content: center;
  padding: 0px 16px;
  color: white;
  order: 1;
  flex: 20 1;
  flex-direction: column;
  margin-top: 0;
  width: auto;
  text-align: left;
  font-size: 20px;
}

.question-icon2 {
  background-color: #5b5786;
  display: flex;
  align-items: center;
  height: 55px;
  padding: 0 15px;
  color: white;
  order: 2;
  height: 68px;
  overflow: hidden;
}

.question-icon2 > img {
  height: 22px;
  transform: rotateZ(180deg);
}

.expanded .question-icon2 > img {
  transform: none;
}

.q-icon {
  fill: white;
}

.hiddentext2 {
  display: none;
  margin-top: 10px;
  margin-bottom: 10px;
  color: white;
  font-size: 12px;
  padding: 0 15px;
  text-align: left;
}

.expanded {
  height: fit-content;
}

.expanded .hiddentext2 {
  display: flex;
  flex-direction: row;
}

@media screen and (min-width: 800px) {
  .hiddentext2 {
    font-size: 16px;
  }
}
