@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");

#content{
  width: 80%;
  margin:0 auto;
}

.biggerImage img {
  /* height:300px; */
}

.menuButton {
  position: fixed;
  width: 46px;
  height: 46px;
  left: 12px;
  top: 36px;
  background: #726da8;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
}

.despreText {
  /* Despre */
  width: 110px;
  height: 60px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 120%;
  text-align: left;
  /* identical to box height, or 29px */

  /* mov */

  color: #726da8;
}

.titleUnderline {
  /* Rectangle 39 */

  width: 155px;
  height: 4px;
  background: #1a936f;
  border-radius: 12px 0px 0px 12px;
  margin-bottom: 24px;
}
.despreDateLocation-flex{
  display: flex;
}


.aboutSubtitle-flex {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
}

.firstAboutParagraph {
  width: fit-content;
  height: fit-content;
  margin: auto;
  margin-top: 16px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  padding-right: 36px;
  /* or 24px */

  text-align: justify;
  letter-spacing: -0.01em;

  /* mov */

}

.flex-dataBox {
  display: flex;
  justify-content: center;
  margin-top: 12px;
  height: 55px;
  width: 100%;
  margin-top: 28px;
}

.aboutSubtitle {
  width: 183px;
  height: 56px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  /* or 29px */

  text-align: left;

  /* mov */

  color: #726da8;
}

.sfatUnderline {
  /* Rectangle 39 */

  width: 60%;
  height: 4px;
  background: #1a936f;
  border-radius: 0px 12px 12px 0px;
}

.flex-bulletList {
  display: flex;
  flex-direction: column;
  gap:15px;
  height: 200px;
  width: 100%;
  margin-bottom:36px;
}

#bullet {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
.flex-bulletList .flex-bullet:nth-child(even) #bullet {
  background-color: #EF7B45;
  fill: #EF7B45;
}

.flex-bulletList .flex-bullet:nth-child(odd) #bullet{
  background-color: #1A936F;
  fill: #1A936F;
}

.bulletspacing {
  margin-right: 20px;
}

.bulletText {
  width: fit-content;
  height: fit-content;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  margin-left: 24px;
  margin-right: 24px;
  /* or 24px */

  text-align: justify;
  letter-spacing: -0.01em;

}

.team-flex .cardboxFlex:nth-child(even) .imageDetails {
  background-color: #1A936F;
}

.team-flex .cardboxFlex:nth-child(odd) .imageDetails {
  background-color: #EF7B45;
}

.flex-bullet {
  display: flex;
  justify-content: flex-start;
  height: 46px;
  width: 90%;
  margin-top: 12px;
  padding-left: 12px;
}

.teamParagraph {
  width: 86px;
  height: 29px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  /* identical to box height, or 29px */

  /* mov */

  color: #726da8;
}

.cardBox {
  width: 217.44px;
  height: 206.86px;
}

.team-flex {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 120px;
}

.team-flex > .cardboxFlex {
  margin-left: 12px;
  margin-right: 12px;
}

.centerDivFlex {
  display: flex;
  width: 100%;
  height: fit-content;
  justify-content: center;
}

.detailBoxes {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 12px;
}

.questions-flex {
  display: flex;
  width: 100%;
  height: fit-content;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.margin {
  margin-top: 24px;
}

.aplicaAbout {
  display: none;
}

.mobileLogo {
  position: absolute;
  height: 92px;
  width: 200px;
  margin-left: -24px;
}

@media screen and (min-width: 530px) {
  .aboutSubtitle {
    width: 333px;
    height: 58px;
    font-size: 24px;
  }

  .firstAboutParagraph {
    font-size: 18px;
  }

  

  .flex-bullet {
    margin-bottom: 6px;
  }

  .despreText {
    font-size: 30px;
    padding-right: 12px;
  }

  .titleUnderline {
    /* Rectangle 39 */

    width: 25%;
    margin-top: 6px;
  }

  .sfatUnderline {
    /* Rectangle 39 */

    width: 70%;
    margin-top: 0px;
  }
}

@media screen and (min-width: 630px) {
  .sfatUnderline {
    /* Rectangle 39 */

    width: 67%;
    margin-top: 6px;
  }

  .aboutSubtitle {
    font-size: 28px;
    width: fit-content;
  }
}

@media screen and (min-width: 800px) {
  .mobileLogo {
    height: 126.5px;
    width: 275px;
  }

  .despreText {
    /* Despre */
    width: 110px;
    height: 60px;

    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    /* identical to box height, or 29px */

    /* mov */

    color: #726da8;
  }

  .titleUnderline {
    /* Rectangle 39 */

    width: 60%;
    height: 4px;
    background: #1a936f;
    border-radius: 12px 0px 0px 12px;
    margin-bottom: 24px;
    align-self: flex-end;
  }

  .aboutSubtitle-flex {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 24px;
  }

  .firstAboutParagraph {
    width: fit-content;
    height: fit-content;
    margin: auto;
    margin-top: 16px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    padding-right: 15%;

    text-align: justify;
    letter-spacing: -0.01em;

  }

  .flex-dataBox {
    justify-content: center;
    margin-top: 12px;
    height: 55px;
    width: 100%;
    margin-top: 18px;
  }

  /* .dateLocation-flex {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    height: fit-content;
  } */

  .aboutSubtitle {
    width: 494px;
    height: 58px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 120%;
    color: #726da8;
    align-self: center;
    text-align: center;
  }

  .sfatUnderline {
    /* Rectangle 39 */

    width: 85%;
    height: 4px;
    background: #1a936f;
    border-radius: 0px 12px 12px 0px;
  }

  .bulletspacing {
    margin-right: 20px;
  }

  .bulletText {
    width: fit-content;
    height: fit-content;

    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;
    padding-left: 24px;
    padding-right: 24px;
    /* or 24px */

    text-align: justify;
    letter-spacing: -0.01em;

  }

  .flex-bulletList {
    margin-bottom: 24px;
  }

  .teamParagraph {
    width: 86px;
    height: 29px;

    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    /* identical to box height, or 29px */

    /* mov */

    color: #726da8;
  }

  .cardBox {
    width: 246.09px;
    height: 300px;
  }

  .team-flex {
    width: 90%;
    flex: 1 1;
  }

  .team-flex > .cardboxFlex {
    margin-left: 12px;
    margin-right: 12px;
  }

  .bulletquestions-flex{
    display:flex;
    flex-direction: row-reverse;
  }

  .questions-flex {
    margin-top: 24px;
    display: flex;
    width: 100%;
    height: fit-content;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: stretch;
  }

  .margin {
    margin-top: 12px;
  }

  .lastUnderline {
    width: 836px;
    height: 4px;
    /* verde */

    background: #1a936f;
    border-radius: 12px;
  }

  .lastUnderline-flex {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
  }

  .flex-bullet {
    width: 95%;
  }
}

@media screen and (min-width: 840px) {
  .flex-bullet {
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media screen and (min-width: 930px) {
  .flex-bullet {
    justify-content: space-evenly;
  }
}

@media screen and (min-width: 958px) {
  .mobileLogo {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .despreDateLocation-flex{
    display: block;
  }
}
@media screen and (max-width: 1200px) {
  .bulletquestions-flex{
    display:flex;
    flex-direction: column;
    gap:15px;
  }
  .questions-flex {
    align-items: center;
  }
}
@media screen and (min-width: 1200px) {
  .questions-flex {
    align-items: stretch;
  }
}
@media screen and (min-width: 1000px) {
  .sfatUnderline {
    width: 80%;
  }

  .aplicaAbout {
    display: flex;
  }
}

